import React, {useEffect, useRef, useState} from "react";
import {motion, useScroll, useTransform} from "framer-motion";
import styled from "styled-components";
import {COLOR_WHITE} from "../../constants/color";
import logo from "../../images/icons/ch_k_logo_filled_transparent.png";

const IntroductionContainer = styled.div(() => ({
    backgroundImage: `url("https://chalihokilak.s3.eu-central-1.amazonaws.com/chalihokilakfotky/ch_k_vyhodnoceni1.jpeg")`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    boxSizing: "border-box",
    height: "100vh",
    padding: "200px 64px 0 64px",
    "@media (max-width: 800px)": {
        padding: "200px 32px 0 32px",
    },
    "@media (max-width: 600px)": {
        padding: "200px 16px 0 16px",
    },
}));

const SectionContainer = styled.div(() => ({
    alignItems: "center",
    display: "flex",
    gap: 32,
    justifyContent: "center",
}));

const HeaderContainer = styled(motion.div)(() => ({
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    width: "70vw"
}));

const Header = styled(motion.div)(() => ({
    alignItems: "center",
    display: "flex",
    color: COLOR_WHITE,
    fontFamily: "Courier New",
    fontSize: "2rem",
    fontWeight: 600,
    textShadow: "black 1px 5px 5px",
    "@media (max-width: 800px)": {
        fontSize: "1.5rem",
    },
    "@media (max-width: 600px)": {
        fontSize: "1rem",
    },
}));

const SectionImageContainer = styled(motion.div)(() => ({
    display: "flex",
    "& img": {
        backdropFilter: "blur(5px)",
        borderRadius: "50%",
        height: 150,
        width: 150,
        "@media (max-width: 800px)": {
            height: 100,
            width: 100,
        },
        "@media (max-width: 600px)": {
            height: 50,
            width: 50,
        }
    }
}));

const Introduction = () => {
    const {scrollY} = useScroll();
    const ref = useRef(null)
    const [elementHeight, setElementHeight] = useState(0)
    const headerY = useTransform(scrollY, [0, elementHeight / 2], [0, elementHeight / 4]);
    const sectionImageY = useTransform(scrollY, [0, elementHeight / 2.6], [0, elementHeight / 2.3]);
    const sectionImageX = useTransform(scrollY, [elementHeight / 2.6, elementHeight / 1.3], [0, -elementHeight / 2]);

    useEffect(() => {
        if (ref.current) {
            setElementHeight(ref.current.scrollHeight)
        }

    }, [ref])

    return (
        <IntroductionContainer ref={ref} id="introduction">
            <SectionContainer>
                <SectionImageContainer style={{y: sectionImageY, x: sectionImageX}}>
                    <img src={logo} alt="Chaliho kilák logo"/>
                </SectionImageContainer>
                <HeaderContainer style={{y: headerY}}>
                    <Header>
                        Jsi milovník sportu a zároveň milovník piva? Tahle akce je něco pro tebe! Zaběhni si jeden
                        kilometr se 3 zastávkami na pivo a zjisti svoje limity.
                    </Header>
                </HeaderContainer>
            </SectionContainer>
        </IntroductionContainer>
    )
}

export default Introduction;