import React from "react";
import styled from "styled-components";
import {COLOR_BLACK, COLOR_WHITE} from "../../constants/color";
import {motion} from "framer-motion";
import InstagramIcon from "../../images/icons/instagram_icon_rounded.png";
import FacebookIcon from "../../images/icons/facebook_icon_rounded.png";
import TikTokIcon from "../../images/icons/icon_tiktok_rounded_black_transparent.png";
import LinkTreeIcon from "../../images/icons/linktree_logo.png";

const SocialSectionContainer = styled.div(() => ({
    alignItems: "center",
    background: COLOR_WHITE,
    boxSizing: "border-box",
    display: "flex",
    flexDirection: "column",
    gap: 16,
    justifyContent: "center",
    padding: 32,
}));

const SocialSectionHeader = styled.div(() => ({
    color: COLOR_BLACK,
    fontSize: "2rem",
    textAlign:"center",
    "@media (max-width: 600px)": {
        fontSize: "1rem",
    },
}));

const SocialSectionSocialButtonImg = styled.img(() => ({
    height: "50px",
    width: "50px",
    "@media (max-width: 600px)": {
        height: "30px",
        width: "30px",
    },
}));

const EventSocialButtonSectionContainer = styled(motion.div)`
  border-radius: 16px;
  display: flex;
  justify-content: center;
  gap: 32px;
  padding: 24px;

  :hover {
    box-shadow: 0 2px 5px ${COLOR_BLACK};
  }
`

const EventSectionSocialButtonLink = styled(motion.a)`
  border: 0;
  background: transparent;
  border-radius: 50%;
  cursor: pointer;
  padding: 8px 8px 3px 8px;

  :hover {
    box-shadow: 0 2px 5px ${COLOR_BLACK};
  }

,
`

const SocialSiteSection = () => {

    return (
        <SocialSectionContainer>
            <SocialSectionHeader>
                Naleznete nás i na sociálních sítích.
            </SocialSectionHeader>
            <EventSocialButtonSectionContainer whileHover={{scale: 1.1}}
            >
                <EventSectionSocialButtonLink whileHover={{scale: 1.1}}
                                              href={"https://www.instagram.com/chaliho_kilak/"} rel={"noreferrer"}
                                              target={"_blank"}>
                    <SocialSectionSocialButtonImg src={InstagramIcon} alt={""}/>
                </EventSectionSocialButtonLink>
                <EventSectionSocialButtonLink whileHover={{scale: 1.1}} href={"https://www.facebook.com/chalihokilak/"}
                                              rel={"noreferrer"}
                                              target={"_blank"}>
                    <SocialSectionSocialButtonImg src={FacebookIcon} alt={""}/>
                </EventSectionSocialButtonLink>
                <EventSectionSocialButtonLink whileHover={{scale: 1.1}} href={"https://www.tiktok.com/@chaliho.kilak"}
                                              rel={"noreferrer"}
                                              target={"_blank"}>
                    <SocialSectionSocialButtonImg src={TikTokIcon} alt={""}/>
                </EventSectionSocialButtonLink>
                <EventSectionSocialButtonLink whileHover={{scale: 1.1}} href={"https://linktr.ee/chaliho_k"}
                                              rel={"noreferrer"} target={"_blank"}>
                    <SocialSectionSocialButtonImg src={LinkTreeIcon} alt={""}/>
                </EventSectionSocialButtonLink>
            </EventSocialButtonSectionContainer>
        </SocialSectionContainer>

    )
}

export default SocialSiteSection;