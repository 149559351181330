import React from "react";
import styled from "styled-components";
import {COLOR_BEER, COLOR_WHITE} from "../../constants/color";
import {motion} from "framer-motion";

const VideoSectionContainer = styled.div(() => ({
    background: COLOR_BEER,
    boxSizing: "border-box",
    display: "flex",
    justifyContent: "center",
    padding: 64,
    "@media (max-width: 800px)": {
        padding: "64px 32px",
    },
    "@media (max-width: 600px)": {
        padding: "64px 16px",
    },
}));

const VideoSectionText = styled(motion.div)(() => ({
    color: COLOR_WHITE,
    fontSize: "2rem",
    fontWeight: "bold",
    textAlign: "center",
    textShadow: "black 1px 5px 5px",
    "@media (max-width: 800px)": {
        fontSize: "1.5rem",
    },
    "@media (max-width: 600px)": {
        fontSize: "1rem",
    },
}));
const ContentContainer = styled.div(() => ({
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    gap: 16,
    width:"80%",
    "@media (max-width: 800px)": {
        width:"90%",
    },
    "@media (max-width: 600px)": {
        width:"100%",
    },
}));

const EmbedVideoContainer = styled.div(() => ({
    height:"100%",
    overflow:"hidden",
    paddingBottom:"56.25%",
    position:"relative",
    width:"100%",
}));

const EmbedVideo = styled.iframe(() => ({
    height:"100%",
    left:0,
    position:"absolute",
    top:0,
    width:"100%",
}));


const VideoSection = ({titleText, videoSrc}) => {

    return (
        <VideoSectionContainer>
            <ContentContainer>
                <VideoSectionText
                    animate={{scale: [1, 1.1, 1]}}
                    transition={{
                        duration: 1,
                        ease: "easeInOut",
                        repeat: Infinity,
                        repeatDelay: 2,
                    }}
                >
                    {titleText}
                </VideoSectionText>
                <EmbedVideoContainer>
                    <EmbedVideo src={videoSrc}
                                title="YouTube video player" frameBorder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                allowFullScreen/>
                </EmbedVideoContainer>
            </ContentContainer>
        </VideoSectionContainer>
    )
}

export default VideoSection;