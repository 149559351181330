import React, {useEffect, useRef, useState} from "react";
import styled from "styled-components";
import {COLOR_BEER, COLOR_BLACK, COLOR_WHITE} from "../../constants/color";
import icon_expand_more_white from "../../images/icons/icon_expand_more_white.svg";
import icon_expand_less_white from "../../images/icons/icon_expand_less_white.svg";
import icon_expand_more_black from "../../images/icons/icon_expand_more_black.svg";
import icon_expand_less_black from "../../images/icons/icon_expand_less_black.svg";

const MenuItemContainer = styled.button`
  align-items: center;
  border: 0;
  background: transparent;
  color: ${props => `${props.isScrolled ? COLOR_BLACK : COLOR_WHITE}`};
  cursor: pointer;
  display: flex;
  font-family: Courier New, monospace;
  font-size: 1rem;
  font-weight: bold;
  position: relative;
  transition: all .5s ease-out;

  :hover {
    color: ${COLOR_BEER};
  }
`

const MenuItemIcon = styled.img(() => ({
    height: 20,
    width: 20,
}));

const MenuItemExpandedContainer = styled.div(() => ({
    background: COLOR_WHITE,
    borderRadius:6,
    boxShadow: `0px 2px 5px ${COLOR_BLACK}`,
    display:"flex",
    flexDirection:"column",
    gap:4,
    padding:8,
    position: "absolute",
    top: 25,
    zIndex: 2,
}));

const MenuItem = ({isScrolled, text, onClick, menu = false, children}) => {
    const [isExpanded, setIsExpanded] = useState(false)

    const dropdownRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (
                dropdownRef.current &&
                !dropdownRef.current.contains(event.target)
            ) {
                setIsExpanded(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [dropdownRef, setIsExpanded]);

    return (
        <>
            {menu ? (
                <MenuItemContainer {...{isScrolled}} onClick={() => setIsExpanded(!isExpanded)}>
                    {text}
                    <MenuItemIcon
                        src={isExpanded ? (isScrolled ? icon_expand_less_black : icon_expand_less_white) : (isScrolled ? icon_expand_more_black : icon_expand_more_white)}
                        alt={""}/>
                    {isExpanded && <MenuItemExpandedContainer ref={dropdownRef}>{children}</MenuItemExpandedContainer>}
                </MenuItemContainer>
            ) : (
                <MenuItemContainer {...{isScrolled}} onClick={onClick}>
                    {text}
                </MenuItemContainer>
            )}
        </>
    )
}

export default MenuItem