import React from "react";
import styled from "styled-components";
import {COLOR_BEER, COLOR_NEUTRAL_90, COLOR_WHITE} from "../../constants/color";
import {motion} from "framer-motion";
import icon_mail_white from "../../images/icons/icon_mail_white.svg";


const ContactSectionContainer = styled.div(() => ({
    background: COLOR_NEUTRAL_90,
    boxSizing: "border-box",
    display: "flex",
    flexDirection: "column",
    padding: 64,
    "@media (max-width: 1000px)": {
        padding: "64px 32px",
    },
    "@media (max-width: 600px)": {
        padding: "64px 16px",
    },
}));

const ContactSectionHeader = styled(motion.div)(() => ({
    color: COLOR_WHITE,
    fontSize: "3rem",
    fontWeight: "bold",
    textAlign: "center",
    "@media (max-width: 1000px)": {
        fontSize: "2rem",
    },
}));

const ContactContainer = styled(motion.div)(() => ({
    alignItems: "center",
    display: "flex",
    gap: 16,
    marginTop: 32,
    "@media (max-width: 1000px)": {
        flexDirection: "column",
        gap: 32,
    },
}));

const MapContainer = styled(motion.div)(() => ({
    color: COLOR_BEER,
    display: "flex",
    flexDirection: "column",
    flex: 1,
    fontWeight: "bold",
    fontSize: "1rem",
    gap: 8,
    "@media (max-width: 1000px)": {
        width: "100%",
    },
}));

const InfoContainer = styled(motion.div)(() => ({
    flexDirection: "column",
    flex: 1,
}));

const InfoContainerHeader = styled(motion.div)(() => ({
    color: COLOR_WHITE,
    textAlign: "center",
    fontSize: "1.5rem",
    fontWeight: "bold",
}));

const MailIcon = styled(motion.img)(() => ({
    height: 30,
    width: 30,
}));

const InfoContainerText = styled(motion.div)(() => ({
    alignItems: "center",
    color: COLOR_WHITE,
    display: "flex",
    fontSize: "1rem",
    gap: 8,
    justifyContent: "center",
    marginTop: 8,
}));

const InfoContainerLink = styled.a`
  color: ${COLOR_BEER};
  cursor: pointer;
  font-family: Courier New, monospace;
  font-size: 1rem;
  font-weight: bold;
  text-decoration:underline;
  :hover {
    color: ${COLOR_BEER};
    text-decoration: none;
  }
`

const EmbedMap = styled(motion.iframe)(() => ({
    border: 0,
    display: "flex",
    flexDirection: "column",
    height: "500px",
    width: "100%",
}));

const ContactSection = () => {

    return (
        <ContactSectionContainer>
            <ContactSectionHeader>Jak nás najdete? Snadno!</ContactSectionHeader>
            <ContactContainer>
                <MapContainer>
                    <div>Nepál aréna (fotbalové hřiště), Nepolisy, 503 63</div>
                    <EmbedMap
                        loading="lazy"
                        title="Chaliho kilák mapa"
                        src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d2554.23158468989!2d15.4640812!3d50.194202!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x470c23878fe3a399%3A0xe698004dd5f11e29!2zRm90YmFsb3bDqSBoxZlpxaF0xJsgVGogU29rb2wgTmVwb2xpc3k!5e0!3m2!1scs!2scz!4v1677171301230!5m2!1scs!2scz"
                    />
                </MapContainer>
                <InfoContainer>
                    <InfoContainerHeader>Máš nějaký dotaz?</InfoContainerHeader>
                    <InfoContainerHeader>Neváhej a napiš nám!</InfoContainerHeader>
                    <InfoContainerText>
                        <MailIcon
                            src={icon_mail_white}
                            alt={""}/>
                        <InfoContainerLink href="mailto:chalihokilak@gmail.com">chalihokilak@gmail.com</InfoContainerLink></InfoContainerText>
                </InfoContainer>
            </ContactContainer>
        </ContactSectionContainer>
    )
}

export default ContactSection;