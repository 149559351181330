import React, {useState} from "react";
import styled from "styled-components";
import {
    COLOR_BEER,
    COLOR_NEUTRAL_90,
    COLOR_WHITE_10,
} from "../../constants/color";
import beer from "../../images/icons/pivo.png";
import {motion} from "framer-motion";

const HistorySectionContainer = styled(motion.div)(() => ({
    alignItems: "center",
    backgroundColor: COLOR_NEUTRAL_90,
    boxSizing: "border-box",
    display: "flex",
    gap: 32,
    justifyContent: "center",
    overflow: "hidden",
    padding: 64,
    "@media (max-width: 1000px)": {
        flexDirection: "column",
        padding:"64px 16px 64px 16px",
    },
}));

const SectionImageContainer = styled(motion.div)(() => ({
    display: "flex",
    "& img": {
        height: 400,
        width: "auto",
        "@media (max-width: 800px)": {
            height: 60,
            width: 50,
        }
    }
}));

const HistorySectionContentContainer = styled(motion.div)(() => ({
    backgroundColor: COLOR_WHITE_10,
    borderRadius: 16,
    boxSizing: "border-box",
    boxShadow: "inset 0 0 10px black",
    display: "flex",
    flexDirection: "column",
    gap: 16,
    maxWidth: "60%",
    padding: 16,
    "@media (max-width: 1000px)": {
        maxWidth: "100%",
    },
}));

const HistoryHeaderText = styled.div(() => ({
    color: COLOR_BEER,
    fontSize: "2.5rem",
    fontWeight: "bold",
    textAlign: "center",
    "@media (max-width: 1000px)": {
        fontSize: "2rem",
    },
    "@media (max-width: 600px)": {
        fontSize: "1.5rem",
    },
}));

const HistoryParagraphText = styled.div(() => ({
    color: COLOR_BEER,
    fontSize: "1.5rem",
    fontWeight: "bold",
    textAlign: "center",
    "@media (max-width: 1000px)": {
        fontSize: "1rem",
    },
    "@media (max-width: 600px)": {
        fontSize: "0.8rem",
    },

}));

const HistorySection = () => {
    const [isInView, setIsInView] = useState(false);

    const setInView = () => {
        setIsInView(true)
    }
    const setOutOfView = () => {
        setIsInView(false)
    }

    return (
        <HistorySectionContainer
            id="historySection"
            whileInView={setInView}
            onViewportLeave={setOutOfView}
        >
            <SectionImageContainer
                animate={
                    isInView ? {
                        x: 0,
                        rotate: 0,
                        opacity: 1,
                        transition: {
                            type: "spring",
                            stiffness: 40,
                        }
                    } : {
                        x: "-100vw",
                        rotate: 180,
                        opacity: 0,
                        transition: {
                            type: "spring",
                            stiffness: 40,
                        }
                    }
                }>
                <img src={beer} alt="Chaliho kilák logo"/>
            </SectionImageContainer>
            <HistorySectionContentContainer
                animate={
                    isInView ? {
                        x: 0,
                        opacity: 1,
                        transition: {
                            type: "spring",
                            stiffness: 30,
                            delay: 0.5,
                        }
                    } : {
                        x: "100vw",
                        opacity: 0,
                        transition: {
                            type: "spring",
                            stiffness: 30,
                        }
                    }
                }>
                <HistoryHeaderText>Jak nás napadlo pořádat Chaliho Kilák ?</HistoryHeaderText>
                <HistoryParagraphText>
                    Psal se květen roku 2019 v malebné vesničce ve východních Čechách a my s Chalim měli zrovna období,
                    kdy nám trošku vyrostly pivní pupíčky a přemýšleli o tom, jak se dostat zpět do formy. A tak nás
                    napadl veliký plán. Co si tak udělat menší Nepolišský Spartan race? Přišlo nám to jako skvělý nápad a
                    tak jsme začali s megalomanskými plány. Při všech těch nápadech, jak bude Spartan vypadat, jsme si
                    říkali, že by bylo super udělat i menší doprovodnou disciplínu, kde by se na krátké trati vypila 3
                    piva na čas. Zkrátka jen taková sranda. Po prvním nadšení, jak už to tak někdy bývá, nastalo ticho
                    po pěšině…
                    Ale asi po měsíci, někdy při učení patologie, jsem přišel k Chalimu doplnit síly. Ten pohotově
                    donesl pivko, Chali senior ugriloval klobásky a najednou na nás vyskočilo video, kde chlapík exuje
                    pivko a při cestě k dalšímu ho ze sebe vyhodí. A jak to už u piva bývá, strhla se debata o tom,
                    jestli to je těžké nebo ne. Jelikož Chaliho je těžké k něčemu přesvědčit, asi tak jako dostat v
                    první třídě jedničku, tak za chvíli exoval první pivko, běžel na konec ulice, kde vypil druhé, které
                    už v sobě neudržel. Já i Chali senior jsme se mu vysmáli a on řekl: ,,Chci vidět vás dva i ostatní,
                    jak to zvládnete." Na Nepolišský Spartan race sice nikdy nedošlo, ale měsíc od tohohle pokusu se
                    běžel první ročník Chaliho kiláku.
                </HistoryParagraphText>
            </HistorySectionContentContainer>
        </HistorySectionContainer>
    )
}

export default HistorySection;