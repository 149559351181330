import Header from "./components/Header/Header";
import {createGlobalStyle} from "styled-components";
import {COLOR_WHITE} from "./constants/color";
import Introduction from "./components/Introduction/Introduction";
import EventSection from "./components/EventSection/EventSection";
import VideoSection from "./components/VideoSection/VideoSection";
import SocialSiteSection from "./components/SocialSiteSection/SocialSiteSection";
import HistorySection from "./components/HistorySection/HistorySection";
import WinnerSection from "./components/WinnerSection/WinnerSection";
import ContactSection from "./components/ContactSection/ContactSection";
import Footer from "./components/Footer/Footer";
import {CookieConsent} from "react-cookie-consent";

const GlobalStyle = createGlobalStyle`
  body {
    background-color: ${COLOR_WHITE};
    font-family: "Courier New", monospace;
  }

  ,
`

function App() {
    return (
        <>
            <GlobalStyle/>
            <Header/>
            <Introduction/>
            <VideoSection titleText={"Vychutnej si video z běhu minulého ročníku a přijď na další ročník."}
                          videoSrc={"https://www.youtube.com/embed/Z6a305JZhDs"}/>
            <EventSection/>
            <VideoSection titleText={"Nevíš, co tě čeká? Podívej se na video z běhu."}
                          videoSrc={"https://www.youtube.com/embed/6O3OmAu4imI"}/>
            <HistorySection/>
            <VideoSection
                titleText={"Nemůžeš běžet, nebo se ti nechce? Nevadí, po běhu následují další hry zakončené skvělou párty."}
                videoSrc={"https://www.youtube.com/embed/b0uuA2342iI"}/>
            <WinnerSection/>
            <SocialSiteSection/>
            <ContactSection/>
            <Footer/>
            <CookieConsent
                style={{textAlign: "center", fontSize: "1rem", alignItems: "center", justifyContent: "center"}}
                buttonStyle={{borderRadius: 4, fontSize: "1rem"}}
                buttonText={"Rozumím"}>
                Tato webová stránka využívá soubory třetích stran tzv. Cookies.</CookieConsent>
        </>
    );
}

export default App;
