import React, {useState} from "react";
import styled from "styled-components";
import {motion} from "framer-motion";
import {COLOR_BEER, COLOR_BLACK} from "../../constants/color";
import iconTimer from "../../images/icons/icon_timer_black.svg";

const WinnerSectionContainer = styled(motion.div)(() => ({
    backgroundImage: `url("https://chalihokilak.s3.eu-central-1.amazonaws.com/chalihokilakfotky/ch_k_vitez_2022_2.jpeg")`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundColor: COLOR_BEER,
    backgroundPosition: "center",
    boxSizing: "border-box",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    overflow: "hidden",
    padding: "64px 0px",
}));

const WinnerSectionHeader = styled(motion.div)(() => ({
    color: COLOR_BLACK,
    fontSize: "3rem",
    fontWeight: "bold",
    textAlign: "center",
}));

const ImagesContainer = styled(motion.div)(() => ({
    boxSizing: "border-box",
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
    padding: "32px 16px 32px 16px",
    gap: 16,
    "@media (max-width: 1000px)": {
        justifyContent: "center",
    },
    "@media (max-width: 600px)": {
        alignItems: "center",
        display: "flex",
        flexDirection: "column",
        gap: 32,
    }
}));

const WinnerContainer = styled(motion.div)(() => ({
    alignItems: "center",
    background: COLOR_BEER,
    borderRadius: 12,
    boxShadow: `0px 2px 5px ${COLOR_BLACK}`,
    display: "flex",
    flexDirection: "column",
    overflow: "hidden",
    padding: 8,
    width: "20%",
    "@media (max-width: 1000px)": {
        width: "28%",
    },
    "@media (max-width: 600px)": {
        width: "90%",
    },
}));

const WinnerPhoto = styled(motion.img)(() => ({
    height: 500,
    marginBottom: 8,
    objectFit: "cover",
    position: "center",
    width: "100%",
    "@media (max-width: 1000px)": {
        height: 400,
    },
}));

const WinnerContainerHeader = styled(motion.div)(() => ({
    color: COLOR_BLACK,
    fontSize: "2rem",
    fontWeight: "bolder",
    textAlign: "center",
}));

const WinnerInfo = styled(motion.div)(() => ({
    alignItems: "center",
    color: COLOR_BLACK,
    display: "flex",
    gap: 4,
    fontSize: "1rem",
    fontWeight: "bold",
    textAlign: "center",
}));

const TimerIcon = styled(motion.img)(() => ({
    marginTop: -2,
    height: 18,
    width: 18,
}));

const WinnerSection = () => {
    const [isInView, setIsInView] = useState(false);

    const setInView = () => {
        setIsInView(true)
    }
    const setOutOfView = () => {
        setIsInView(false)
    }

    const winnerVariant = (delay) => {
        if (isInView) return {
            x: 0,
            opacity: 1,
            transition: {
                type: "spring",
                stiffness: 30,
                delay: delay ? delay : 0,
            }
        }
        else return {
            x: "100vw",
            opacity: 0,
            transition: {
                type: "spring",
                stiffness: 30,
            }
        }
    }

    return (
        <WinnerSectionContainer id="winnerSection"
                                whileInView={setInView}
                                onViewportLeave={setOutOfView}>
            <WinnerSectionHeader>Síň slávy</WinnerSectionHeader>
            <ImagesContainer>
                <WinnerContainer animate={winnerVariant(0.2)}>
                    <WinnerContainerHeader>2023</WinnerContainerHeader>
                    <WinnerPhoto src={"https://chalihokilak.s3.eu-central-1.amazonaws.com/chalihokilakfotky/ch_k_vitez_2023.jpeg"}
                                 alt={"https://chalihokilak.s3.eu-central-1.amazonaws.com/chalihokilakfotky/ch_k_vitez_2023.jpeg"} loading="lazy"/>
                    <WinnerInfo>Filip Jelínek</WinnerInfo>
                    <WinnerInfo>
                        <TimerIcon src={iconTimer} alt="Chaliho kilák logo"/>
                        5:30
                    </WinnerInfo>
                </WinnerContainer>
                <WinnerContainer animate={winnerVariant(0.4)}>
                    <WinnerContainerHeader>2023</WinnerContainerHeader>
                    <WinnerPhoto
                        src={"https://chalihokilak.s3.eu-central-1.amazonaws.com/chalihokilakfotky/ch_k_vitezka_2023.jpeg"}
                        alt={"https://chalihokilak.s3.eu-central-1.amazonaws.com/chalihokilakfotky/ch_k_vitezka_2023.jpeg"} loading="lazy"/>
                    <WinnerInfo>Lenka Bížová</WinnerInfo>
                    <WinnerInfo>
                        <TimerIcon src={iconTimer} alt="Chaliho kilák logo"/>
                        6:54
                    </WinnerInfo>
                </WinnerContainer>
                <WinnerContainer animate={winnerVariant(0.2)}>
                    <WinnerContainerHeader>2022</WinnerContainerHeader>
                    <WinnerPhoto src={"https://chalihokilak.s3.eu-central-1.amazonaws.com/chalihokilakfotky/ch_k_vitez_2022.jpeg"}
                                 alt={"https://chalihokilak.s3.eu-central-1.amazonaws.com/chalihokilakfotky/ch_k_vitez_2022.jpeg"} loading="lazy"/>
                    <WinnerInfo>Lukáš Němec</WinnerInfo>
                    <WinnerInfo>
                        <TimerIcon src={iconTimer} alt="Chaliho kilák logo"/>
                        5:58
                    </WinnerInfo>
                </WinnerContainer>
                <WinnerContainer animate={winnerVariant(0.4)}>
                    <WinnerContainerHeader>2022</WinnerContainerHeader>
                    <WinnerPhoto
                        src={"https://chalihokilak.s3.eu-central-1.amazonaws.com/chalihokilakfotky/ch_k_vitezka_2022.webp"}
                        alt={"https://chalihokilak.s3.eu-central-1.amazonaws.com/chalihokilakfotky/ch_k_vitezka_2022.webp"} loading="lazy"/>
                    <WinnerInfo>Lenka Bížová</WinnerInfo>
                    <WinnerInfo>
                        <TimerIcon src={iconTimer} alt="Chaliho kilák logo"/>
                        7:40
                    </WinnerInfo>
                </WinnerContainer>
                <WinnerContainer animate={winnerVariant(0.6)}>
                    <WinnerContainerHeader>2021</WinnerContainerHeader>
                    <WinnerPhoto src={"https://chalihokilak.s3.eu-central-1.amazonaws.com/chalihokilakfotky/ch_k_vitez_2021.jpeg"}
                                 alt={"https://chalihokilak.s3.eu-central-1.amazonaws.com/chalihokilakfotky/ch_k_vitez_2021.jpeg"} loading="lazy"/>
                    <WinnerInfo>Ondra Němec</WinnerInfo>
                    <WinnerInfo>
                        <TimerIcon src={iconTimer} alt="Chaliho kilák logo"/>
                        6:12
                    </WinnerInfo>
                </WinnerContainer>
                <WinnerContainer animate={winnerVariant(0.8)}>
                    <WinnerContainerHeader>2020</WinnerContainerHeader>
                    <WinnerPhoto src={"https://chalihokilak.s3.eu-central-1.amazonaws.com/chalihokilakfotky/ch_k_vitez_2020.jpeg"}
                                 alt={"https://chalihokilak.s3.eu-central-1.amazonaws.com/chalihokilakfotky/ch_k_vitez_2020.jpeg"} loading="lazy"/>
                    <WinnerInfo>Ondra Němec</WinnerInfo>
                    <WinnerInfo>
                        <TimerIcon src={iconTimer} alt="Chaliho kilák logo"/>
                        6:02
                    </WinnerInfo>
                </WinnerContainer>
                <WinnerContainer animate={winnerVariant(1)}>
                    <WinnerContainerHeader>2019</WinnerContainerHeader>
                    <WinnerPhoto src={"https://chalihokilak.s3.eu-central-1.amazonaws.com/chalihokilakfotky/ch_k_vitez_2019.jpeg"}
                                 alt={"https://chalihokilak.s3.eu-central-1.amazonaws.com/chalihokilakfotky/ch_k_vitez_2019.jpeg"} loading="lazy"/>
                    <WinnerInfo>Honza Zhříval</WinnerInfo>
                    <WinnerInfo>
                        <TimerIcon src={iconTimer} alt="Chaliho kilák logo"/>
                        zapomenut
                    </WinnerInfo>
                </WinnerContainer>
            </ImagesContainer>
        </WinnerSectionContainer>
    )
}

export default WinnerSection;