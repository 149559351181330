import React from "react";
import styled from "styled-components";
import {
    COLOR_BEER,
    COLOR_BLACK,
    COLOR_WHITE,
} from "../../constants/color";
import {motion} from "framer-motion";


const EventSectionContainer = styled.div(() => ({
    backgroundImage: `url("https://chalihokilak.s3.eu-central-1.amazonaws.com/chalihokilakfotky/ch_k_skupinovka1.webp")`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundColor: COLOR_BEER,
    backgroundPosition: "center",
    display: "flex",
    flexDirection: "column",
    gap: 16,
    overflow: "hidden",
    padding: "64px",
    position: "relative",
    "@media (max-width: 800px)": {
        padding: "64px 32px 64px 32px",
    },
    "@media (max-width: 600px)": {
        padding: "64px 16px 64px 16px",
    },
}));

const EventSectionTextContainer = styled.div(() => ({
    display: "flex",
    flexDirection: "column",
    gap: 16,
}));

const EventSectionHeader = styled.div(() => ({
    color: COLOR_WHITE,
    fontSize: "3rem",
    fontWeight: "bold",
    marginBottom: 16,
    textAlign: "center",
    textShadow: "black 1px 5px 5px",
    "@media (max-width: 800px)": {
        fontSize: "2.5rem",
    },
    "@media (max-width: 600px)": {
        fontSize: "2rem",
    },
}));

const EventSectionParagraph = styled.div(() => ({
    color: COLOR_WHITE,
    fontSize: "2rem",
    fontWeight: "bold",
    textAlign: "center",
    textShadow: "black 1px 5px 5px",
    "& span": {
        color: COLOR_BEER,
        fontWeight: "bolder",
    },
    "@media (max-width: 800px)": {
        fontSize: "1.5rem",
    },
    "@media (max-width: 600px)": {
        fontSize: "1rem",
    },
}));

const EventSectionParagraphDate = styled(motion.div)(() => ({
    color: COLOR_BEER,
    fontWeight: "bolder",
}));

const EventSectionRegistrationButtonContainer = styled.div(() => ({
    display: "flex",
    justifyContent: "center",
}));

const EventSectionRegistrationLink = styled(motion.a)(() => ({
    background: COLOR_BLACK,
    border: `3px solid ${COLOR_BLACK}`,
    borderRadius: 16,
    color: COLOR_WHITE,
    cursor: "pointer",
    fontSize: "2rem",
    fontFamily: "Courier New",
    fontWeight: "bold",
    padding: "16px 32px",
    textDecoration: "none",
    "@media (max-width: 800px)": {
        fontSize: "1.5rem",
    },
    "@media (max-width: 600px)": {
        fontSize: "1rem",
    },
}));

const EventSection = () => {

    return (
        <EventSectionContainer id="eventSection">
            <EventSectionTextContainer>
                <EventSectionHeader>Oficiální termín Chaliho kiláku vol 6. je konečně tady!</EventSectionHeader>
                <EventSectionParagraph>Zapiš si do kalendáře datum
                    <EventSectionParagraphDate animate={{scale: [1, 1.1, 1, 1.1,1], rotate: [0,5,0,-5,0]}}
                                transition={{
                                    duration: 1,
                                    ease: "easeInOut",
                                    repeat: Infinity,
                                    repeatDelay: 1,
                                }}>
                        22.6.2024
                    </EventSectionParagraphDate>
                    a začni trénovat.
                </EventSectionParagraph>
                <EventSectionParagraph>
                    Minulý ročník byla skvělá jízda a jsme rádi, že vás akce stále baví stejně jako nás
                    a proto se snažíme posunout tento ročník zase o kousek dál. Proto si přijď zaběhnout kilák a dát u
                    toho
                    tři pivka i letos. Těšíme se na vás a sleduj naše sociální sítě ohledně dalších novinek!
                </EventSectionParagraph>
                <EventSectionParagraph>
                    Ještě ti nebylo 18? Nevadí, pro mladistvé máme připravená nealkoholická piva.
                </EventSectionParagraph>
            </EventSectionTextContainer>

            <EventSectionHeader>
                Registrovat se
            </EventSectionHeader>
            <EventSectionRegistrationButtonContainer>
                <EventSectionRegistrationLink
                    href={"https://docs.google.com/forms/d/e/1FAIpQLSc_v849BKe9QjzFlKeF0bdbKqFUl59oU3kJXjhJmRUZYrgsYQ/closedform"}
                    whileHover={{scale: 1.1}}
                    target={"_blank"}
                    rel={"noreferrer"}
                >

                    Hlavní běh
                </EventSectionRegistrationLink>
            </EventSectionRegistrationButtonContainer>
            <EventSectionRegistrationButtonContainer>
                <EventSectionRegistrationLink
                    href={"https://docs.google.com/forms/d/e/1FAIpQLScLBUh6CT1E3qYr242Oyd-x5GCYJ8IFGl4MbD0rWWcWT6D7Hg/viewform"}
                    whileHover={{scale: 1.1}}
                    target={"_blank"}
                    rel={"noreferrer"}
                >

                    Chali cup
                </EventSectionRegistrationLink>
            </EventSectionRegistrationButtonContainer>
            <EventSectionRegistrationButtonContainer>
                <EventSectionRegistrationLink
                    href={"https://docs.google.com/forms/d/e/1FAIpQLSfmGxK3JqYzKd3C4G3OnyCUcJjqrPRCcX_rnYgYepHrFr_B7A/viewform"}
                    whileHover={{scale: 1.1}}
                    target={"_blank"}
                    rel={"noreferrer"}
                >

                    Pivní hry
                </EventSectionRegistrationLink>
            </EventSectionRegistrationButtonContainer>
        </EventSectionContainer>
    )
}

export default EventSection