import React, {useEffect, useRef, useState} from "react";
import styled from "styled-components";
import logo from "../../images/icons/ch_k_logo_filled_transparent.png";
import icon_open_new from "../../images/icons/icon_open_new_black.svg";
import icon_menu_black from "../../images/icons/icon_menu_black.svg";
import icon_menu_white from "../../images/icons/icon_menu_white.svg";
import {
    COLOR_BEER,
    COLOR_BLACK,
    COLOR_BLACK_80,
    COLOR_WHITE,
} from "../../constants/color";
import MenuItem from "./MenuItem";
import {useWindowSize} from "react-use";
import CloseIcon from "../../images/icons/icon_close_black.svg";
import icon_expand_more_black from "../../images/icons/icon_expand_more_black.svg";
import icon_expand_less_black from "../../images/icons/icon_expand_less_black.svg";
import {motion} from "framer-motion";

const HeaderContainer = styled.div(props => ({
    alignItems: "center",
    background: props.isScrolled ? COLOR_WHITE : "transparent",
    boxShadow: props.isScrolled ? `0px 2px 5px ${COLOR_BLACK}` : 0,
    boxSizing: "border-box",
    display: "flex",
    height: 70,
    justifyContent: "space-between",
    position: "fixed",
    transition: "all .5s ease-out",
    width: "100vw",
    zIndex: 100,
}));

const TitleContainer = styled.div(props => ({
    color: props.isScrolled ? COLOR_BLACK : COLOR_WHITE,
    fontFamily: "Courier New",
    fontSize: "3rem",
    fontWeight: "bold",
    padding: "0 0 0 32px",
    transition: "all .5s ease-out",
}));

const MenuContainer = styled.div(() => ({
    display: "flex",
    gap: 16,
}));

const MenuExpandedItem = styled.a`
  align-items: center;
  color: ${COLOR_BLACK};
  cursor: pointer;
  display: flex;
  font-family: Courier New, monospace;
  font-size: 1.2rem;
  font-weight: bold;
  gap: 16px;
  transition: all .5s ease-out;

  :hover {
    color: ${COLOR_BEER};
  }
`

const MenuItemExpanded = styled.img(() => ({
    height: 20,
    marginTop: -2,
    width: 20,
}));

const LogoContainer = styled.button(props => ({
    alignItems: "center",
    background: props.hover ? (props.isScrolled ? COLOR_BEER : COLOR_WHITE) : "transparent",
    border: 0,
    borderRadius: "50%",
    cursor: "pointer",
    display: "flex",
    justifyContent: "center",
    margin: "8px 8px 8px 0",
    transition: "all .5s ease-out",
    "& img": {
        height: 50,
        width: 50,
    }
}));

const MenuIconContainer = styled.button`
  align-items: center;
  background: transparent;
  border: 0;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  margin-left: 8px;
  transition: all .5s ease-out;
  
  :hover {
    background: ${props => `${props.isScrolled ? COLOR_BEER : "transparent"}`};
  }
`

const MenuIcon = styled.img(() => ({
    height: 50,
    width: 50
}));

const MobileNavBackground = styled.div(() => ({
    background: COLOR_BLACK_80,
    bottom: 0,
    left: 0,
    position: "fixed",
    right: 0,
    top: 0,
    zIndex: 999
}));

const MobileNavContainer = styled(motion.div)(() => ({
    background: `linear-gradient(180deg, ${COLOR_WHITE} 20%, ${COLOR_BEER} 40%)`,
    boxSizing:"border-box",
    display: "flex",
    flexDirection: "column",
    gap: 8,
    height: "100vh",
    padding: "16px 50px 16px 16px",
    position: "relative",
    width: "50vw",
    "@media (max-width: 400px)": {
        width: "70vw",
    },
}));

const MobileNavItem = styled(motion.div)`
  align-items: center;
  //background: red;
  cursor: pointer;
  display: flex;
  font-family: Courier New, monospace;
  font-size: 1.2rem;
  font-weight: bold;
  padding: 8px 0;
  position: relative;
  transition: all .5s ease-out;

  :hover {
    color: ${COLOR_BEER};
  }
`

const ListIcon = styled.img(() => ({
    height: 30,
    width: 30
}));

const MobileExpandedListContainer = styled.div(() => ({
    display:"flex",
    flexDirection:"column",
    gap:16,
    marginLeft:16,
}));

const MobileNavCloseButton = styled.button(() => ({
    background: "transparent",
    border: 0,
    cursor: "pointer",
    position: "absolute",
    right: 0,
    top: 0,
    "& img": {
        height: 40,
        width: 40,
    },
}));

const photo_links = [
    {
        link:"https://www.rajce.idnes.cz/chalihokilak/album/chaliho-kilak-2023",
        text:"2023"
    },
    {
        link:"https://chalihokilak.rajce.idnes.cz/Chaliho_kilak_2022/",
        text:"2022"
    },
    {
        link:"https://chalihokilak.rajce.idnes.cz/Chaliho_kilak_2021/",
        text:"2021"
    },
    {
        link:"https://chalihokilak.rajce.idnes.cz/Chaliho_kilak_2020/",
        text:"2020"
    }
]

const Header = () => {
    const [hover, setHover] = useState(false)
    const [isScrolled, setIsScrolled] = useState(false)
    const [isMobileNavOpen, setIsMobileNavOpen] = useState(false)
    const [isListExpanded, setIsListExpanded] = useState(false)
    const mobileNavRef = useRef();
    const {width} = useWindowSize();
    const isMobileView = width <= 900;

    const handleScroll = () => {
        if (window.scrollY > 5) {
            setIsScrolled(true);
        } else {
            setIsScrolled(false);
        }
    };

    const handleClickScroll = (elementId) => () => {
        const element = document.getElementById(elementId);
        if (element) {
            element.scrollIntoView({behavior: 'smooth'});
            setIsMobileNavOpen(false)
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [isScrolled]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (
                mobileNavRef.current &&
                !mobileNavRef.current.contains(event.target)
            ) {
                setIsMobileNavOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [mobileNavRef, setIsMobileNavOpen]);

    return (
        <HeaderContainer {...{isScrolled}}>

            {!isMobileView ? (
                <>
                    <TitleContainer {...{isScrolled}}>Chaliho kilák</TitleContainer>
                    <MenuContainer>
                        <MenuItem isScrolled={isScrolled} text={"fotky"} menu>
                            {photo_links.map((link) => (
                                <MenuExpandedItem key={link.text}
                                                    href={link.link}
                                                  target={"_blank"}
                                                  rel={"noreferrer"}
                                >
                                    {link.text}
                                    <MenuItemExpanded src={icon_open_new} alt=""/>
                                </MenuExpandedItem>

                            ))}
                        </MenuItem>
                        <MenuItem isScrolled={isScrolled} text={"o nás"} onClick={handleClickScroll("historySection")}/>
                        <MenuItem isScrolled={isScrolled} text={"síň slávy"}
                                  onClick={handleClickScroll("winnerSection")}/>
                        <MenuItem isScrolled={isScrolled} text={"registrace"}
                                  onClick={handleClickScroll("eventSection")}/>
                    </MenuContainer>
                </>
            ) : (
                <>
                    <MenuIconContainer {...{isScrolled}} onClick={() => setIsMobileNavOpen(true)}>
                        <MenuIcon src={isScrolled ? icon_menu_black : icon_menu_white} alt="Chaliho kilák logo"/>
                    </MenuIconContainer>
                    {isMobileNavOpen && (
                        <MobileNavBackground>
                            <MobileNavContainer ref={mobileNavRef}
                                                initial={{opacity: 0, x: "-100vw"}}
                                                animate={{opacity: 1, x: 0}}
                                                transition={{duration: 0.5}}
                            >
                                <MobileNavCloseButton onClick={() => setIsMobileNavOpen(false)}>
                                    <img src={CloseIcon} alt={""}/>
                                </MobileNavCloseButton>
                                <MobileNavItem onClick={() => setIsListExpanded(!isListExpanded)}>
                                    Fotky
                                    <ListIcon src={isListExpanded ? icon_expand_less_black : icon_expand_more_black}
                                              alt={""}/>
                                </MobileNavItem>
                                {isListExpanded && (
                                    <MobileExpandedListContainer>
                                        {photo_links.map((link) => (
                                            <MenuExpandedItem key={link.text}
                                                              href={link.link}
                                                              target={"_blank"}
                                                              rel={"noreferrer"}
                                            >
                                                {link.text}
                                                <MenuItemExpanded src={icon_open_new} alt=""/>
                                            </MenuExpandedItem>
                                        ))}
                                    </MobileExpandedListContainer>
                                )}
                                <MobileNavItem onClick={handleClickScroll("historySection")}>
                                    O nás
                                </MobileNavItem>
                                <MobileNavItem onClick={handleClickScroll("winnerSection")}>
                                    Síň slávy
                                </MobileNavItem>
                                <MobileNavItem onClick={handleClickScroll("eventSection")}>
                                    Registrace
                                </MobileNavItem>
                            </MobileNavContainer>
                        </MobileNavBackground>
                    )}
                </>
            )}

            <LogoContainer onClick={handleClickScroll("introduction")} onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)} {...{
                isScrolled,
                hover
            }}>
                <img src={logo} alt="Chaliho kilák logo"/>
            </LogoContainer>
        </HeaderContainer>

    )
}

export default Header;