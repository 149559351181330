import React from "react";
import styled from "styled-components";
import {COLOR_BLACK, COLOR_WHITE} from "../../constants/color";
import pivovarNB from "../../images/images/logo_pivovar_nb.png";
import elzim from "../../images/images/logo_elzim.jpg";
import lekar from "../../images/images/logo_backovsky_lekar.png";
import nepolisy from "../../images/images/logo_obec_nepolisy.png";
import wildcock from "../../images/images/logo_wild_cock.jpg";
import bradna from "../../images/images/logo_autoservis_bradna.png";
import {motion} from "framer-motion";

const FooterContainer = styled.div(() => ({
    background: COLOR_WHITE,
    padding: "16px",

}));

const SponsorsContainer = styled.div(() => ({
    alignItems: "center",
    color: COLOR_BLACK,
    display: "flex",
    fontSize: "2rem",
    justifyContent: "space-between",
    "@media (max-width: 800px)": {
        flexDirection: "column",
        gap: 16,
    },
}));

const SponsorContainer = styled(motion.a)`
  :hover {
    cursor: pointer;
  }
`

const SponsorImg = styled.img(() => ({
    height: 40,
    "@media (max-width: 900px)": {
        height: 30,
    },
    "@media (max-width: 800px)": {
        height: 40,
    },
}));


const Footer = () => {

    return (
        <FooterContainer>
            <SponsorsContainer>
                Sponzoři:
                <SponsorContainer
                    href={"https://www.firmy.cz/detail/2072552-backovsky-prosec.html"} target={"_blank"} rel={"noreferrer"}
                    whileHover={{scale: 1.1}}>
                    <SponsorImg src={lekar} alt="Bačkovský s.r.o - Lékař"/>
                </SponsorContainer>
                <SponsorContainer
                    href={"http://www.autoservis-bradna.cz/"} target={"_blank"} rel={"noreferrer"}
                    whileHover={{scale: 1.1}}>
                    <SponsorImg src={bradna} alt="Bačkovský s.r.o - Lékař"/>
                </SponsorContainer>
                <SponsorContainer
                    href={"https://www.elzim.cz/"} target={"_blank"} rel={"noreferrer"}
                    whileHover={{scale: 1.1}}>
                    <SponsorImg src={elzim} alt="Elzim elektro"/>
                </SponsorContainer>
                <SponsorContainer
                    href={"https://www.nepolisy.cz/"} target={"_blank"} rel={"noreferrer"}
                    whileHover={{scale: 1.1}}>
                    <SponsorImg src={nepolisy} alt="Obec Nepolisy"/>
                </SponsorContainer>
                <SponsorContainer
                    href={"https://pivovarbydzov.cz/"} target={"_blank"} rel={"noreferrer"}
                    whileHover={{scale: 1.1}}>
                    <SponsorImg src={pivovarNB} alt="Pivovar Nový Bydžov"/>
                </SponsorContainer>
                <SponsorContainer
                    href={"http://www.wildcock.cz/"} target={"_blank"} rel={"noreferrer"}
                    whileHover={{scale: 1.1}}>
                    <SponsorImg src={wildcock} alt="Pivovar Wild Cock"/>
                </SponsorContainer>
            </SponsorsContainer>
        </FooterContainer>
    )
}

export default Footer;